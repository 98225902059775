import React, { useState } from 'react';
import Papa from 'papaparse';
import './App.css';
import sheet from "./sheet.csv";
import logo from './logo.svg';

const App = () => {
    const [input, setInput] = useState('');
    const [message, setMessage] = useState('');

    const handleInputChange = (e) => {
        setInput(e.target.value);
    };

    const searchInCSV = () => {
        Papa.parse(sheet, {
            download: true,
            complete: (results) => {
                const found = results.data.some(row => row.includes(input)) && input !== '';
                setMessage(found ? 'You are allowlisted' : 'You are not allowlisted');
            }
        });
    };

    return (
        <div id="app-container">
          <div id="logocontianer"><img src={logo} id="logo"></img></div>
          <div id="msgcontianer"><p>{message}</p></div>
          <div>
            <input type="text" value={input} placeholder="] ]" onChange={handleInputChange} />
            <button onClick={searchInCSV}>&#x1F50E;</button>
          </div>
            
        </div>
        
    );
};

export default App;
